import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { ReactComponent as LogoIcon } from './icons/bechat_logo_white.svg';

interface Message {
  text: string;
  type: 'message-client' | 'message-bot';
}
function useWindowSize() {
  // Establecer el tamaño inicial de la ventana directamente en el estado
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth, // Usa window.innerWidth como valor inicial
    height: window.innerHeight, // Usa window.innerHeight como valor inicial
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Agregar el event listener cuando el componente se monta
    window.addEventListener('resize', handleResize);

    // Ejecutar handleResize inmediatamente para establecer el tamaño inicial
    handleResize();

    // Limpiar el event listener cuando el componente se desmonte
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
const App: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState<string>('');
  const [userID, setUserID] = useState<string | null>(localStorage.getItem('userID') || '-1');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const [loadedMessagesCount, setLoadedMessagesCount] = useState(15); // Cargar inicialmente 10 mensajes
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  // Usa el hook para detectar el tamaño de la ventana
  const { width } = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      if (chatRef.current) {
        // Si el scroll está en la parte superior y quedan mensajes por mostrar
        if (chatRef.current.scrollTop === 0 && loadedMessagesCount < messages.length) {
          // Determinar cuántos mensajes adicionales cargar, en caso de que queden menos de 10
          const remainingMessages = messages.length - loadedMessagesCount;
          const countToLoad = remainingMessages < 10 ? remainingMessages : 10;
          
          // Calcula la altura antes de añadir los mensajes
          const currentScrollHeight = chatRef.current.scrollHeight;

          setLoadedMessagesCount(loadedMessagesCount + countToLoad);
  
          // Utiliza queueMicrotask para ajustar el scroll después de que React actualice el DOM
          queueMicrotask(() => {
            if (chatRef.current) {
              // Calcula la nueva altura del scroll y ajusta la posición actual
              const newScrollHeight = chatRef.current.scrollHeight;
              chatRef.current.scrollTop = newScrollHeight - currentScrollHeight+50;
              console.log("afsdfasdfasdfasdfasdfasdfasdf");
              console.log(chatRef.current.scrollTop);

            }
          });
        }
      }
    };
  
    // Agrega el event listener
    const currentChatRef = chatRef.current;
    currentChatRef?.addEventListener('scroll', handleScroll);
  
    // Limpia el event listener
    return () => {
      currentChatRef?.removeEventListener('scroll', handleScroll);
    };
  }, [loadedMessagesCount, messages]); // Agrega messages como una dependencia
  


  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Enfocar el input solo si es una tecla alfanumérica o un símbolo de puntuación
      const isAlphaNumericOrSymbol = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/.test(e.key);
      const isControlKey = e.ctrlKey || e.metaKey || e.altKey; // Verificar si se está presionando Control, Command o Option

      if (isAlphaNumericOrSymbol && inputRef.current && !isControlKey) {
        inputRef.current.focus();
      }
    };

    const handleBeforeInput = (e: Event) => {
      const inputEvent = e as InputEvent;
      if (inputEvent.inputType === 'insertFromPaste') {
        // Pegar directamente en el área de entrada al realizar la acción de "pegar"
        e.preventDefault();
        const pastedText = inputEvent.data || '';
        setInputText((prevInputText) => prevInputText + pastedText);
      }
    };

    // Agregar manejadores de eventos
    document.addEventListener('keydown', handleKeyDown);
    inputRef.current?.addEventListener('beforeinput', handleBeforeInput);

    // Limpiar los manejadores al desmontar el componente
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      inputRef.current?.removeEventListener('beforeinput', handleBeforeInput);
    };
  }, []);

  useEffect(() => {
    const recuperarConversacionAnterior = async () => {
      if (userID && userID !== '-1') {
        try {
          const baseURL = process.env.REACT_APP_BACKEND_URL;
          const { data } = await axios.post(`${baseURL}/recuperarConversacionAnterior`, { userID: userID });

          console.log('Conversación anterior recibida del backend:', data);

          // Transforma los datos al formato correcto
          const mensajesTransformados = data.map((mensaje: any) => ({
            text: mensaje.content,
            type: mensaje.sender === 'client' ? 'message-client' : 'message-bot',
          }));

          // Actualiza el estado con los mensajes transformados
          setMessages(mensajesTransformados);
        } catch (error) {
          console.error('Error al recuperar la conversación anterior:', error);
        }
      }
    };

    recuperarConversacionAnterior();
  }, [userID]); // Dependencia para ejecutar este efecto cuando cambie 'userID'


  useEffect(() => {
    console.log('Effect para auto-scroll');
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessageToBackend = async (text: string) => {
    console.log('Enviando mensaje al backend:', text);
    try {

      const baseURL = process.env.REACT_APP_BACKEND_URL;
      const endpoint = userID === '-1' ? `${baseURL}/start` : `${baseURL}/pregunta`;


      console.log('Endpoint seleccionado:', endpoint);
      const { data } = await axios.post(endpoint, {
        pregunta: text,
        userID: userID,
      });

      console.log('Respuesta recibida del backend:', data);
      if (data.userID && userID === '-1') {
        console.log('Actualizando userID:', data.userID);
        setUserID(data.userID);
        localStorage.setItem('userID', data.userID);
      }

      const botMessage: Message = { text: data.respuesta, type: 'message-bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error al enviar mensaje al backend:', error);
    }
  };

  const handleSendMessage = () => {
    console.log('Manejando el envío del mensaje');
    if (inputText.trim() !== '') {
      const userMessage: Message = { text: inputText, type: 'message-client' };
      setMessages(prevMessages => [...prevMessages, userMessage]);

      sendMessageToBackend(inputText);
      setInputText('');
      console.log('Mensaje enviado y campo de texto limpiado');
    }
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    console.log('Manejando la presión de tecla:', e.key);
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="app-container">

      <div className="chat" ref={chatRef} style={{ padding: width < 768 ? '20px' : '60px', width: width < 768 ? '90%' : '40%' }}>
        <div className="chat-background">
          <LogoIcon className="chat-background-logo" />
        </div>

        <div className="message-container">
          {messages.slice(Math.max(messages.length - loadedMessagesCount, 0)).map((message, index) => (

            <div key={index} className={message.type}>

              <pre>{message.text}</pre>
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-container" style={{ paddingBottom: width < 768 ? '10px' : '30px' }}>
        <div className="input-container" style={{ width: width < 768 ? '100%' : '50%' }}>
          <textarea
            ref={inputRef}
            value={inputText}
            className="input"
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Escribe tu mensaje..."
          />
          <button onClick={handleSendMessage}>Enviar</button>
        </div>
      </div>
    </div>
  );
};

export default App;